import { initializeApp } from "firebase/app";
import { getDownloadURL, getStorage, ref } from "firebase/storage";
import { ItemType } from "../types/items";

const firebaseConfig = {
    apiKey: "AIzaSyDjFhPuC94N5ub3zc7chz2e-lY5J_nBzi0",
    authDomain: "pc-intaller.firebaseapp.com",
    projectId: "pc-intaller",
    storageBucket: "pc-intaller.appspot.com",
    messagingSenderId: "532343938414",
    appId: "1:532343938414:web:407cb8740ca540323e757f"
};

const app = initializeApp(firebaseConfig);
const storage = getStorage(app);

export class FirebaseAPI {

    public static async getDownloadUrl(url: string): Promise<string> {
        try {
            const url_res = await getDownloadURL(ref(storage, url))
            return url_res;
        } catch (e) {
            console.log(e)
            return ''
        }
    }

    public static async getItemsDownloadUrls(items: ItemType[]): Promise<ItemType[]> {
        const res: ItemType[] = [];

        for (const item of items) {
            let url = ''

            if (item.isDirectLink) url = item.url
            else url = await this.getDownloadUrl(item.url)

            res.push({
                ...item,
                download_url: url
            })
        }

        return res;
    }
}