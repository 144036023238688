import { useAutoAnimate } from '@formkit/auto-animate/react';
import axios from 'axios';
import React, { useEffect } from 'react';
import { FirebaseAPI } from './API/FirebaseAPI';
import Group from './components/Group/Group';
import WinActivation from './components/WinActivation/WinActivation';
import './styles/index.scss';

function App() {

    const [items, setItems] = React.useState<any>({});
    const [error, setError] = React.useState<null | string>(null);

    const [parent] = useAutoAnimate()

    useEffect(() => {

        async function getItems() {
            try {
                const resp = await axios.get('./items.json')
                const data = resp.data

                setItems(data)

                let allItems: typeof data = JSON.parse(JSON.stringify(data));

                for (const key in data) {
                    const new_items = await FirebaseAPI.getItemsDownloadUrls(data[key])

                    allItems[key] = new_items;
                }

                setItems(allItems)
            } catch (error: any) {
                setError(error.message)
            }
        }

        getItems()

    }, []);

    return (
        <>
            <WinActivation />

            {!error && <div className='app' ref={parent}>

                {typeof items === 'object' && Object.keys(items).map((key) => {
                    return (
                        <Group title={key} items={items[key]} key={key} />
                    )
                })}

            </div >}

            {error && <p className='error'><p style={{ color: 'white' }}>Error :( <br /> Try to reload page later </p> <br /> Error details: {error}</p>}
        </>
    );
}

export default App;
