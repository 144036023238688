import React, { useEffect } from 'react';
import { FirebaseAPI } from '../../API/FirebaseAPI';
import { ItemType } from '../../types/items';
import Item from '../Item/Item';
import './Group.scss';

interface Props {
    items: ItemType[];
    title: string;
}

export default function Group({ items, title }: Props) {
    const [currentItems, setCurrentItems] = React.useState<ItemType[]>(items);

    useEffect(() => {
        async function getItems() {
            setCurrentItems(await FirebaseAPI.getItemsDownloadUrls(currentItems))
        }

        getItems()

    }, [items]);

    return (
        <div className='group'>
            <h1 className='title'>{title}</h1>

            <div className='elements'>
                {items.map((item) => <Item item={item} key={item.name} />)}
            </div>
        </div >
    );
}
