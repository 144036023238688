import React, { useEffect } from 'react'
import { FirebaseAPI } from '../../API/FirebaseAPI'
import Item from '../Item/Item'
import './WinActivation.scss'

export default function WinActivation() {

    const [downloadURL, setDownloadURL] = React.useState<string>('')

    useEffect(() => {
        async function getDownloadUrl() {
            const url = await FirebaseAPI.getDownloadUrl('/activate.rar')
            setDownloadURL(url)
        }

        getDownloadUrl()
    }, [])

    return (
        <div className='activation'>
            <Item item={{ name: 'Windows activation', img_url: 'img/ico/windows.svg', url: 'activate.rar', download_url: downloadURL, isDirectLink: false }} />
        </div>
    )
}