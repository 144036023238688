import React, { useEffect, useState } from 'react';
import { ItemType } from '../../types/items';
import './Item.scss';

interface Props {
    item: ItemType;
}

export default function Item({ item }: Props) {

    const [downloadUrl, setDownloadUrl] = useState<string>(item.download_url || '');

    useEffect(() => {
        setDownloadUrl(item.download_url || '');
    }, [item.download_url]);

    useEffect(() => {
        if (!item.isDirectLink && downloadUrl) console.log(downloadUrl)
    }, [downloadUrl]);

    return (
        <>
            {(item.isDirectLink || (!item.isDirectLink && downloadUrl)) &&
                <a className='item' href={downloadUrl} download>
                    <img src={item.img_url} alt={item.name} />
                    <h2 className='item_title'>{item.name}</h2>
                </a>
            }
        </>
    )
}
